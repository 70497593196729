<template>
  <div class="app-check-wrapper">
    <!--  APP扫码核验  暂时做一个简单款  APP在链接里面传入二维码code和用户token   -->
    <div class="title">{{detailData.content && detailData.content.basic.name}}</div>
    <div class="form-detail" v-if="!invalidMsg">
      <template v-for="(item, index) of flattenList" >
        <template v-if="item.type != 'qrcode'">
          <div v-if="item.wx_show" class="form-item" :key="index">
            <span class="label">{{ item.label }}：</span>
            <div class="value">
              <!-- 还有附件的预览，图片的预览，带数据组件的展示 -->
              <template v-if="item.type == 'computed'">
                <computed-parser
                    :value="item"
                    :list="detailData.content.form.formSetting"
                ></computed-parser>
              </template>
              <template v-else-if="item.type == 'uploader'&&item.subType == 'attachment'">
                <attachments :fileList.sync="item.value"></attachments>
              </template>
              <template v-else-if="item.type == 'uploader'&&item.subType == 'image'">
                <div class="upload-img">
                  <el-image v-for="img in item.value" class="imgs" :key="img.url" :src="img.url" :preview-src-list="[img.url]"></el-image>
                </div>
              </template>
              <template v-else>
                {{ getValue(item) }}
                <capital-text
                    v-if="item.isCapital"
                    :value="item.value"
                    class="capital"
                ></capital-text>
              </template>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="form-item">
            <span class="label">核验时间：</span>
            <div class="value">
              {{getValue(item)}}
              <div class="tips" v-if="item.delay">核验时间前后可延长{{item.delay}}分钟</div>
            </div>
          </div>
        </template>
      </template>
    </div>
    <div class="operate" v-if="detailData.content&&!invalidMsg">
      <div class="btn btn-pass mgr10" @click="pass">通过</div>
      <div class="btn btn-reject" @click="reject">驳回</div>
    </div>
    <div class="invalid" v-if="invalidMsg">{{invalidMsg}}</div>
  </div>
</template>

<script>

import axios from "axios";
import {flatten} from "../../../components/dynamicForm/utils/utils";
import ComputedParser from "../../../components/dynamicForm/components/JSONParser/ComputedParser";
import Attachments from "../examine/formDetail/attachments";
import CapitalText from "../../../components/dynamicForm/components/JSONParser/CapitalText";
import {formatTime} from "./utils/utils";
import {getPrefixUrl} from "@/services/fetch";
const url = getPrefixUrl();
export default {
  name: "verificate",
  components: {CapitalText, Attachments, ComputedParser},
  data(){
    return {
      detailData:{},
      _code:'',
      _token:'',
      invalidMsg:''
    }
  },
  mounted(){
    this._code=JSON.parse(this.$route.params.code).code;
    this._token=this.$route.params.token;
    this.getFormInfo()
  },
  computed: {
    flattenList() {
      if (this.detailData.content) {
        return flatten(this.detailData.content.form.formSetting);
      } else {
        return [];
      }
    },
  },
  methods:{
    getFormInfo(){
      axios.get(`${url}/business/approval/dynamic/detail/qr`,{
        params:{code:this._code},
        headers:{
          'Content-Type':'application/x-www-form-urlencoded',
          'token':this._token
        }
      }).then(({data:{data,code,msg}})=>{
        if(code==200){
          this.detailData = data
        }else{
          this.invalidMsg = msg
        }
      }).catch(err=>{
        this.invalidMsg = err
      })
    },
    getValue(item) {
      if (['multiMonthChildren', 'scheduleChildren'].includes(item.myChildrenType)) {
        if (['activityType','activitySchedule','cardType'].includes(item.myName)) {
          return item.value && item.value.name;
        }
        if (['activityNames'].includes(item.myName)) {
          return item.value && item.value.map(ele => ele.name).join(',');
        }
        if (['months'].includes(item.myName)) {
          return Array.isArray(item.value) ? item.value.join() : item.value
        }
      }
      if (["multipleSelector", "singleSelector"].includes(item.type) && !['multiMonthChildren', 'scheduleChildren'].includes(item.myChildrenType)) {
        return (item.options.filter((e) => e.id == item.value||(item.value||[]).includes(e.id)) || {}).reduce((res,e)=>{res.push(e.value);return res},[]).join(',');
      }
      if (item.type=='cascader') {
        return item.value?(item.value.constructor==Array?item.value.reduce((res,e)=>{res.push(e.name);return res},[]).join(','):item.value.name):''
      }
      if (item.type == "area") {
        return item.value&&item.value.name;
      }
      if (item.type == "computed") {
        return item.computed_value||'';
      }
      if(item.type == "dateTime"){
        return formatTime(item.value, item.props.format=='yyyy-MM-dd'?"Y-M-D":"Y-M-D h:m")

      }
      if(item.type == "qrcode"){
        let start = formatTime(item.value[0], "Y-M-D h:m")
        let end = formatTime(item.value[1], "Y-M-D h:m")
        return `${start}  至  ${end}`
      }
      return item.value;
    },

    pass(){
      this.verficate(1)
    },

    verficate(status){
      let params = {
        committer:this.detailData.createBySysUserName.id,
        marketId:this.detailData.userShop.marketId,
        shopNo:this.detailData.userShop.shopNo,
        status:status,
        type:this.detailData.content.basic.name,
        processId:this.detailData.id
      }
      axios.post(`${url}/business/guard/check/insert`,params,{headers:{'Content-Type':'application/json','token':this._token}}).then(({data:{data,code,msg}})=>{
        if(code==200){
          this.invalidMsg = '已核验'
        }else{
          this.invalidMsg = msg
        }
      }).catch(err=>{
        this.invalidMsg = err
      })
    },

    reject(){
      this.verficate(2)
    }
  }

}
</script>

<style scoped lang="scss">
.app-check-wrapper{
  background: #fff;
  min-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  font-size: 14px;
  @media screen and (max-width:374px){
    font-size: 12px;
  }
  @media screen  and (min-width:375px) and (max-width:640px){

  }
  @media screen and (min-width:641px) and (max-width:1200px){
    font-size: 20px;
  }
  @media screen and (min-width:1201px) and (max-width:1440px){
    font-size: 22px;
  }
  .title{
    font-size: 20px;
    padding: 25px 0 10px 0;
    text-align: center;
    font-weight: bold;
    @media screen and (max-width:374px){
      font-size: 18px;
    }
    @media screen  and (min-width:375px) and (max-width:640px){

    }
    @media screen and (min-width:641px) and (max-width:1200px){
      font-size: 26px;
    }
    @media screen and (min-width:1201px) and (max-width:1440px){
      font-size: 28px;
    }

  }
  .invalid{
    text-align: center;
    margin: 40px 0;
    font-size: 18px;
    @media screen and (max-width:374px){
      font-size: 16px;
    }
    @media screen  and (min-width:375px) and (max-width:640px){

    }
    @media screen and (min-width:641px) and (max-width:1200px){
      font-size: 24px;
    }
    @media screen and (min-width:1201px) and (max-width:1440px){
      font-size: 28px;
    }
  }
  .form-detail {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin: 10px 15px;
    @media screen and (max-width:374px){
      margin: 6px 12px;
    }
    @media screen  and (min-width:375px) and (max-width:640px){

    }
    @media screen and (min-width:641px) and (max-width:1200px){
      margin: 20px 35px;
    }
    @media screen and (min-width:1201px) and (max-width:1440px){
      margin: 20px 40px;
    }
    .form-item {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      padding: 8px 0;
      width: 100%;
      box-sizing:border-box;
      @media screen and (max-width:374px){
        padding: 4px 0;
      }
      @media screen  and (min-width:375px) and (max-width:640px){

      }
      @media screen and (min-width:641px) and (max-width:1200px){
        padding: 8px 5px;
        width: 50%;
      }
      @media screen and (min-width:1201px) and (max-width:1440px){
        padding: 8px 10px;
        width: 50%;
      }
      .label {
        text-align: right;
      }
      .value {
        flex: 1;
        word-break: break-word;
        .tips{
          color:#9d9d9d;
        }
        .upload-img {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          .imgs{
            width: 80px;
            height: 80px;
            margin-right: 5px;
            @media screen and (max-width:374px) {
              width: 72px;
              height: 72px;
            }
            @media screen  and (min-width:375px) and (max-width:640px) {

            }
            @media screen and (min-width:641px) and (max-width:1200px) {
              width: 120px;
              height: 120px;
            }
            @media screen and (min-width:1201px) and (max-width:1440px) {
              width: 135px;
              height: 135px;
            }
          }
        }
      }
    }
  }
  .operate{
    text-align: center;
    display: flex;
    height: 40px;
    margin: 20px 15px 40px 15px;

    .btn{
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #5f5e5e;
      height: 40px;
      color: #fff;
      @media screen and (max-width:374px){
        font-size: 12px;
      }
      @media screen  and (min-width:375px) and (max-width:640px){

      }
      @media screen and (min-width:641px) and (max-width:1200px){
        font-size: 20px;
      }
      @media screen and (min-width:1201px) and (max-width:1440px){
        font-size: 22px;
      }
      &.btn-pass{
        background-color: #5c6be8;
      }
      &.btn-reject{
        background-color: #da001b;
      }
    }
  }
}
</style>
<style lang="scss">
    .attachments {
      width: auto !important;

      .file-item {
        .msg {
          @media screen and (max-width: 374px) {
            margin-left: 10px !important;
            width: 100px !important;
          }
        }

        .tool {
          display: none !important;
        }
      }
    }
</style>